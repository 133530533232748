export const profile = {
  en: {
    "profile.my_profile": "My Profile",
    "profile.full_name": "Full name",
    "profile.email": "Email",
    "profile.birth": "Date of birth",
    "profile.v_status": "Verification status",
    "profile.start_verification": "Start verification",
    "profile.phone_number": "Phone number",
    "profile.country": "Country",
  },

  ru: {
    "profile.my_profile": "Мой профиль",
    "profile.full_name": "Имя и фамилия",
    "profile.email": "E-mail",
    "profile.birth": " Дата рождения",
    "profile.v_status": "Статус проверки",
    "profile.start_verification": "Начать проверку",
    "profile.phone_number": "Номер телефона",
    "profile.country": "Страна",
  },

  es: {
    "profile.my_profile": "Mi perfil",
    "profile.full_name": "Nombre completo",
    "profile.email": "Correo electrónico",
    "profile.birth": "Fecha de nacimiento",
    "profile.v_status": "Estado de verificación",
    "profile.start_verification": "Comenzar verificación",
    "profile.phone_number": "Número de teléfono",
    "profile.country": "País",
  },
};

export default profile;
