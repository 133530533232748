export const tooltip = {
  en: {
    "tooltip.withdraw":
      "You can withdraw money from your balance to your bank card or electronic purse you used for depositing.\n" +
      "            You can request withdrawal any time. Your withdrawal requests are processed in 3 business days.",
    // Нові переклади
    "tooltip.store_safely":
      "Please, write down or print a copy of the key above and store it safely.",
    "tooltip.amount": "Specify the exact amount of your trade",
    "tooltip.time":
      "Set the server time (UTC+3) when your trading operation will be closed. By placing a “Higher” or “Lower” forecast you will receive the result exactly at",
  },

  ru: {
    "tooltip.withdraw":
      "Вы можете вывести деньги со своего баланса на банковскую карту или электронный кошелек, который вы использовали для внесения депозита.\n" +
      "            Вы можете запросить вывод средств в любое время. Ваши запросы на вывод средств обрабатываются в течение 3 рабочих дней.",
    "tooltip.store_safely":
      "Пожалуйста, запишите или распечатайте копию ключа, указанного выше, и сохраните ее.",
    "tooltip.amount": "Укажите точную сумму вашей сделки",
    "tooltip.time":
      "Установите время сервера (UTC+3), когда ваша торговая операция будет закрыта. Поставив прогноз «Выше» или «Ниже» вы получите результат ровно на",
  },

  es: {
    "tooltip.withdraw":
      "Puedes retirar dinero de tu saldo a tu tarjeta bancaria o monedero electrónico que utilizaste para depositar.\n" +
      "            Puedes solicitar un retiro en cualquier momento. Tus solicitudes de retiro se procesan en 3 días hábiles.",

    "tooltip.store_safely":
      "Por favor, escribe o imprime una copia de la clave de arriba y guárdala de forma segura.",
    "tooltip.amount": "Especifica el monto exacto de tu operación de trading",
    "tooltip.time":
      "Establece la hora del servidor (UTC+3) en la que se cerrará tu operación de trading. Al realizar una predicción de 'Mayor' o 'Menor' recibirás el resultado exactamente a las",
  },
};

export default tooltip;
