import config from "../config";

export const auth = {
  en: {
    "auth.sign_up": "Sign up",
    "auth.create_password": "Create a new password",
    "auth.create_password_text": "Please choose a new account password",
    "auth.new_password": "New password",
    "auth.confirm_password": "Confirm password",
    "auth.update_password": "Update password",
    "auth.enter_google_code": "Enter Google verification code",
    "auth.enter_sms_code": "Enter SMS verification code",
    "auth.enter_email_code": "Enter Email code",
    "auth.log_in": "Log in",
    "auth.first_time": `First time on ${config.default_title}?`,
    "auth.forgot_pass": "Forgot password?",
    "auth.reset_pass_title": "Password reset",
    "auth.reset_pass_text":
      "Please enter your email address to receive a password reset code",
    "auth.reset_pass": "Reset password",
    "auth.registration_completed": "Registration completed",
    "auth.registration_completed_t":
      "You have successfully created an account.",
    "auth.dashboard": "Go to Dashboard",
    "auth.create_acc": "Create account",
    "auth.sign_in": "Sign in",
    "auth.have_acc": "Already have an account?",
    "auth.requirements1": "The minimum password length is - 8 characters.",
    "auth.requirements2": "Contains both numbers and letters.",
    "auth.requirements3": "Special characters are allowed.",
    "auth.updated_pass": "Password updated!",
    "auth.updated_pass_t1": "Your password has been changed successfully.",
    "auth.updated_pass_t2": "Use your new password to log in.",
    "auth.agree_to": "I agree to the",
    "auth.terms": "Terms of Use",
    "auth.and": "and",
    "auth.p_p": "Privacy Policy",
    "auth.cookie_policy": "Cookie Policy",
    "auth.check_in_box1": "Check your inbox",
    "auth.check_in_box2_1": "An email has been sent to",
    "auth.check_in_box2_2":
      "Follow the link in the email to confirm your email.",
  },

  ru: {
    "auth.sign_up": "Регистрация",
    "auth.create_password": "Укажите пароль",
    "auth.create_password_text":
      "Пожалуйста, выберите новый пароль учетной записи",
    "auth.new_password": "Новый пароль",
    "auth.confirm_password": "Подтвердите пароль",
    "auth.update_password": " Обновите пароль",
    "auth.enter_google_code": "Введите код 2FA код с Вашего приложения, ",
    "auth.enter_sms_code": "Введите код с SMS",
    "auth.enter_email_code": "Введите код с e-mail",
    "auth.log_in": "Войти",
    "auth.first_time": `Впервые на ${config.default_title}?`,
    "auth.forgot_pass": "Забыли пароль?",
    "auth.reset_pass_title": "Восстановить пароль",
    "auth.reset_pass_text":
      "Пожалуйста, введите свой адрес e-mail, чтобы получить код сброса пароля",
    "auth.reset_pass": "Восстановить пароль",
    "auth.registration_completed": "Регистрация завершена",
    "auth.registration_completed_t": "Вы успешно создали учетную запись.",
    "auth.dashboard": "Войти в главное меню",
    "auth.create_acc": "Создать учетную запись",
    "auth.sign_in": "Войти",
    "auth.have_acc": "У вас уже есть аккаунт?",
    "auth.requirements1": "Минимальная длина пароля – 8 символов.",
    "auth.requirements2": "Должен содержать как цифры, так и буквы.",
    "auth.requirements3": "Допускаются специальные символы.",
    "auth.updated_pass": "Пароль успешно обновлен!",
    "auth.updated_pass_t1": "Ваш пароль был успешно изменен.",
    "auth.updated_pass_t2": "Для входа в систему используйте новый пароль.",
    "auth.agree_to": "Я согласен с",
    "auth.terms": "Условиями использования",
    "auth.and": "и",
    "auth.p_p": "Политикой конфиденциальности",
    "auth.cookie_policy": "Политикой использования файлов Cookie",
    "auth.check_in_box1": "Проверьте свой почтовый ящик",
    "auth.check_in_box2_1": "На ваш электронный адрес отправлено письмо",
    "auth.check_in_box2_2":
      "Перейдите по ссылке в письме, чтобы подтвердить свой адрес электронной почты.",
  },

  es: {
    "auth.sign_up": "Registrarse",
    "auth.create_password": "Crear una nueva contraseña",
    "auth.create_password_text":
      "Por favor elige una nueva contraseña para tu cuenta",
    "auth.new_password": "Nueva contraseña",
    "auth.confirm_password": "Confirmar contraseña",
    "auth.update_password": "Actualizar contraseña",
    "auth.enter_google_code": "Introduce el código de verificación de Google",
    "auth.enter_sms_code": "Introduce el código de verificación por SMS",
    "auth.enter_email_code":
      "Introduce el código de verificación por correo electrónico",
    "auth.log_in": "Iniciar sesión",
    "auth.first_time": `¿Es la primera vez en ${config.default_title}?`,
    "auth.forgot_pass": "¿Olvidaste tu contraseña?",
    "auth.reset_pass_title": "Restablecer contraseña",
    "auth.reset_pass_text":
      "Por favor, introduce tu dirección de correo electrónico para recibir un código de restablecimiento de contraseña",
    "auth.reset_pass": "Restablecer contraseña",
    "auth.registration_completed": "Registro completado",
    "auth.registration_completed_t": "Has creado una cuenta exitosamente.",
    "auth.dashboard": "Ir al tablero",
    "auth.create_acc": "Crear cuenta",
    "auth.sign_in": "Iniciar sesión",
    "auth.have_acc": "¿Ya tienes una cuenta?",
    "auth.requirements1":
      "La longitud mínima de la contraseña es de 8 caracteres.",
    "auth.requirements2": "Debe contener números y letras.",
    "auth.requirements3": "Se permiten caracteres especiales.",
    "auth.updated_pass": "¡Contraseña actualizada!",
    "auth.updated_pass_t1": "Tu contraseña ha sido cambiada exitosamente.",
    "auth.updated_pass_t2": "Utiliza tu nueva contraseña para iniciar sesión.",
    "auth.agree_to": "Estoy de acuerdo con los",
    "auth.terms": "Términos de uso",
    "auth.and": "y",
    "auth.p_p": "Política de privacidad",
    "auth.cookie_policy": "Política de cookies",
    "auth.check_in_box1": "Revisa tu bandeja de entrada",
    "auth.check_in_box2_1": "Se ha enviado un correo electrónico a",
    "auth.check_in_box2_2":
      "Sigue el enlace en el correo electrónico para confirmar tu dirección de correo electrónico.",
  },
};

export default auth;
