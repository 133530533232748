import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Theme from "@components/library/Sidebar/components/themeMenu/theme";
import {
  HistoryIcon,
  LogoutIcon,
  ProfileIcon,
  SettingsIcon,
  ThemeIcon,
  TradingIcon,
  WalletIcon,
} from "@components/library";

import { removeUserSession } from "@helpers/axios/private.axios";
import { useTranslation } from "@helpers/translate";
import { useDispatch, useSelector } from "react-redux";
import { AUTH, AUTH_PAGE, MAIN_PAGE } from "@constants";

import "./sidebar.sass";

const Sidebar = ({ open }) => {
  const { isDemoUser } = useSelector((state) => state.auth);
  const [themeOpen, setThemeOpen] = useState(false);
  const { __ } = useTranslation();
  const dispatch = useDispatch();

  const logout = () => {
    removeUserSession();
  };

  const openAuthPopup = () => {
    dispatch({ type: AUTH.AUTH_CHANGE_POPUP, payload: true });
  };

  return (
    <aside id="navSidebar" className={`sidebar ${open ? "active" : ""}`}>
      <div className="sidebar__wrapper">
        <nav>
          <ul className="sidebar__list">
            <li className="sidebar__item">
              <NavLink
                to={isDemoUser ? AUTH_PAGE : MAIN_PAGE}
                className="sidebar__nav-link"
              >
                <TradingIcon className="sidebar__nav-icon" />

                <p className="sidebar__nav-text">{__("common.trading")}</p>
              </NavLink>
            </li>
            {isDemoUser ? (
              <>
                <li className="sidebar__item">
                  <button
                    onClick={openAuthPopup}
                    type="button"
                    className="sidebar__nav-link"
                  >
                    <WalletIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.wallet")}</p>
                  </button>
                </li>
                <li className="sidebar__item">
                  <button
                    onClick={openAuthPopup}
                    type="button"
                    className="sidebar__nav-link"
                  >
                    <ProfileIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.profile")}</p>
                  </button>
                </li>
                <li className="sidebar__item">
                  <button
                    onClick={openAuthPopup}
                    type="button"
                    className="sidebar__nav-link"
                  >
                    <HistoryIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.history")}</p>
                  </button>
                </li>
                <li className="sidebar__item">
                  <button
                    onClick={openAuthPopup}
                    type="button"
                    className="sidebar__nav-link"
                  >
                    <SettingsIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.settings")}</p>
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="sidebar__item">
                  <NavLink to="/wallet" className="sidebar__nav-link">
                    <WalletIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.wallet")}</p>
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink to="/profile" className="sidebar__nav-link">
                    <ProfileIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.profile")}</p>
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink to="/trading-history" className="sidebar__nav-link">
                    <HistoryIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.history")}</p>
                  </NavLink>
                </li>
                <li className="sidebar__item">
                  <NavLink to="/settings" className="sidebar__nav-link">
                    <SettingsIcon className="sidebar__nav-icon" />

                    <p className="sidebar__nav-text">{__("common.settings")}</p>
                  </NavLink>
                </li>
              </>
            )}

            <li className="sidebar__item">
              <button
                onClick={() => setThemeOpen(true)}
                type="button"
                className={`sidebar__nav sidebar__nav-link ${
                  themeOpen ? "active" : ""
                }`}
              >
                <ThemeIcon className="sidebar__nav-icon" />
                <p className="sidebar__nav-text">{__("common.theme")}</p>
              </button>
              <Theme open={themeOpen} setOpen={setThemeOpen} />
            </li>

            {!isDemoUser && (
              <li style={{ marginTop: "auto" }} className="sidebar__item">
                <button onClick={logout} className="sidebar__nav-link">
                  <LogoutIcon className="sidebar__nav-icon" />

                  <p className="sidebar__nav-text">{__("common.logout")}</p>
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
