import React, { useEffect, useRef, useState } from "react";
import { widget } from "@assets/charting_library";
import datafeed from "./datafeed";
import useStyles from "@helpers/theme/useStyles";
import { useSelector } from "react-redux";
import { BidNotification } from "@components/library";
import "./index.sass";

export const MyTradingViewWidget = () => {
  const chartContainerRef = useRef();
  const styles = useStyles();
  const [myWidget, setMyWidget] = useState(null);
  const { locale } = useSelector((state) => state.localization);

  const {
    theme: { theme },
    trading: { pair },
    bids: { openedHistory },
  } = useSelector((state) => state);

  const defaultProps = {
    symbol: pair.slug,
    interval: "5",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    toolbar_bg: "#f1f3f6",
    backgroundColor: styles.widget.bgWidget,
    gridColor: styles.widget.grid,
    supports_marks: true,
  };

  const changeSymbol = async () => {
    await myWidget.activeChart().setSymbol(pair.slug, () => {
      showCurrentMarks();
    });
  };

  const showCurrentMarks = () => {
    if (!myWidget) return;

    myWidget.onChartReady(() => {
      myWidget
        .chart()
        .getAllShapes()
        .forEach((entity) => {
          myWidget.chart().removeEntity(entity.id);
        });

      const activeSymbolBids = openedHistory.filter(
        (el) => el.pair_name === pair.name
      );

      activeSymbolBids.forEach((el) => {
        drawMark(el.created_at, el.closing_at, el.opening_price, el.type);
      });
    });
  };

  const drawMark = (open, close, price, type) => {
    const from = new Date(open).getTime() / 1000 + (3600 * 3);

    console.log('open', open, price, from );

    const overridesUp = {
      backgroundColor: "#00AD64",
      borderColor: "#00AD64",
    };

    const overridesDown = {
      backgroundColor: "#FE3D31",
      borderColor: "#FE3D31",
    };

    const overrides = type === "up" ? overridesUp : overridesDown;

    myWidget.activeChart().createShape(
      { time: from, price: Number(price) },
      {
        shape: "horizontal_ray",
        lock: true,
        disableSelection: true,

        overrides: {
          linecolor: "#006dfd",
          size: 150,
          sticker: "doge",
          backgroundColor: "#ffcc00",
          color: "#ff0000",
          linestyle: 3,
        },
      }
    );

    myWidget.activeChart().createMultipointShape(
      [
        { time: from, price: Number(price) },
        {
          time: from,
          price: Number(price),
        },
      ],
      {
        shape: "callout",
        text: `${type === "up" ? "▲" : "▼"}` + price,
        lock: true,
        filled: true,
        disableSelection: true,
        disableUndo: true,
        zOrder: "top",
        overrides: {
          color: "#fcfcfc",
          fontsize: 10,
          fixedSize: true,
          bold: true,
          transparency: 0,
          ...overrides,
        },
      }
    );
  };

  useEffect(() => {
    if (myWidget) {
      showCurrentMarks();
    }
  }, [openedHistory.length, myWidget]);

  useEffect(() => {
    if (myWidget) {
      changeSymbol();
    }
  }, [pair]);

  useEffect(() => {
    const widgetOptions = {
      // timezone: "GMT+1",
      symbol: defaultProps.symbol,
      datafeed: datafeed,
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale,
      disabled_features: [
        "use_localstorage_for_settings",
        // "header_widget",
        "header_symbol_search",
        "header_compare",
        "header_styles",
        "header_indicators",
        "header_chart_type",
        "header_study_templates",
        "header_undo_redo",
        "header_save_load",
        "header_quick_search",
        "header_fullscreen_button",
        "header_saveload",
        "header_screenshot",
        "header_settings",
        "left_toolbar",
        "timeframes_toolbar",
        "legend_widget",
      ],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: theme === "white" ? "light" : "dark",
      fullWidth: true,
      backgroundColor: styles.widget.bgWidget,
      gridColor: styles.widget.grid,
      toolbar_bg: "#f1f3f6",
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(async () => {
      setMyWidget(tvWidget);
    });

    return () => {
      tvWidget.remove();
    };
  }, []);

  useEffect(() => {
    if (myWidget) {
      myWidget.changeTheme(theme === "white" ? "light" : "dark", {
        backgroundColor: styles.widget.bgWidget,
        gridColor: styles.widget.grid,
      });
    }
  }, [theme]);

  return (
    <div className={"TVChartContainer tradingview-widget"}>
      <div
        className="tradingview-widget__wrapper"
        ref={chartContainerRef}
      ></div>
      <BidNotification />
    </div>
  );
};

export default MyTradingViewWidget;
