import React from "react";
import { Grid } from "@mui/material";
import GreenTheme from "@assets/images/icons/green-theme.svg";
import BlackTheme from "@assets/images/icons/black-theme.svg";
import YellowTheme from "@assets/images/icons/yellow-theme.svg";
import WhiteTheme from "@assets/images/icons/white-theme.svg";
import { useDispatch, useSelector } from "react-redux";
import { THEME } from "../../../../../constants";

const SettingsMenu = () => {
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const changeTheme = (theme) => {
    dispatch({ type: THEME.CHANGE_THEME, payload: theme });
  };

  return (
    <div className="change-theme">
      <Grid container spacing="6px">
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("green")}
            type="button"
            className={`change-theme__btn ${theme === "green" ? "active" : ""}`}
          >
            <picture className="change-theme__btn-icon">
              <img src={GreenTheme} />
            </picture>

            <p className="change-theme__text">Green</p>
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("blue")}
            type="button"
            className={`change-theme__btn ${theme === "blue" ? "active" : ""}`}
          >
            <picture className="change-theme__btn-icon">
              <img src={BlackTheme} />
            </picture>

            <p className="change-theme__text">Blue</p>
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("orange")}
            type="button"
            className={`change-theme__btn ${
              theme === "orange" ? "active" : ""
            }`}
          >
            <picture className="change-theme__btn-icon">
              <img src={YellowTheme} />
            </picture>

            <p className="change-theme__text">Orange</p>
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("dark-green")}
            type="button"
            className={`change-theme__btn ${
              theme === "dark-green" ? "active" : ""
            }`}
          >
            <picture className="change-theme__btn-icon">
              <img src={BlackTheme} />
            </picture>

            <p className="change-theme__text">Dark green</p>
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("red")}
            type="button"
            className={`change-theme__btn ${theme === "red" ? "active" : ""}`}
          >
            <picture className="change-theme__btn-icon">
              <img src={GreenTheme} />
            </picture>

            <p className="change-theme__text">Red</p>
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            onClick={() => changeTheme("white")}
            type="button"
            className={`change-theme__btn ${theme === "white" ? "active" : ""}`}
          >
            <picture className="change-theme__btn-icon">
              <img src={WhiteTheme} />
            </picture>

            <p className="change-theme__text">White</p>
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SettingsMenu;
