import React, { useEffect } from "react";
import Car from "@assets/images/bg/hero-car.png";
import { Grid } from "@mui/material";
import { Layout } from "@components/layout/Layout";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useTranslation } from "@helpers/translate";
import { ALERT } from "@constants";
import { useDispatch } from "react-redux";

import LeftSwich from "@assets/images/main/left-swich.svg";
import RightSwich from "@assets/images/main/right-swich.svg";
import MobileSwich from "@assets/images/main/mobile-swich.svg";
import Percent from "@assets/images/main/percent-icon.svg";

import "./index.sass";
import { Helmet } from "react-helmet";

export const Main = () => {
  const theme = useTheme();
  const { __ } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();

  useEffect(() => {
    const isBanned = sessionStorage.getItem("isBanned");

    if (isBanned) {
      dispatch({ type: ALERT.ALERT_ERROR, payload: __("common.banned") });
      sessionStorage.removeItem("isBanned");
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>
          {__("seo.main_title")} {__("seo.divider")} {__("seo.title")}
        </title>
      </Helmet>

      <section className="hero">
        {isDesktop ? (
          <>
            <img className="hero__bg-left" src={LeftSwich} />
            <img className="hero__bg-right" src={RightSwich} />
          </>
        ) : (
          <img className="hero__bg-mobile" src={MobileSwich} />
        )}

        <div className="wrapper">
          <div className="hero__wrapper">
            <div className="hero__content">
              <h1 className="hero__title">{__("main.hero.title")}</h1>
              <p className="hero__subtitle">{__("main.hero.subtitle")}</p>
              <Link
                to="/auth/signup"
                className="hero__btn btn btn--middle btn--orange"
              >
                {__("main.hero.button")}
              </Link>
            </div>
          </div>
        </div>
        <picture className="hero__bg">
          <img src={Car} />
        </picture>
      </section>
      <section className="conditions">
        <div className="wrapper">
          <header className="conditions__header">
            <h2 className="conditions__title section-title">
              {__("main.conditions.title")}
            </h2>
          </header>

          <Grid
            rowSpacing={{ lg: "1rem", xs: "20px" }}
            columnSpacing="1rem"
            container
          >
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">$5</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-1")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">$1</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-2")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">$10 000</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-3")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">50+</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-4")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">0%</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-5")}
                </p>
              </article>
            </Grid>
            <Grid item b1440={4} xs={6}>
              <article className="condition-card">
                <h3 className="condition-card__title">100+</h3>
                <p className="condition-card__text">
                  {__("main.conditions.i-6")}
                </p>
              </article>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="why">
        <div className="wrapper">
          <header className="why__header">
            <h2 className="why__title section-title">{__("main.why.title")}</h2>
          </header>

          <Grid
            rowSpacing={{ b1440: "30px", lg: "1rem", xs: "20px" }}
            columnSpacing="1rem"
            container
          >
            <Grid item lg={6} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap">
                  <img className="why-card__icon" src={Percent} />

                  <h3 className="why-card__title">
                    {__("main.why.i-1_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-1_text")}</p>
                </div>

                <picture className="why-card__pic">
                  <source
                    media="(max-width: 1440px)"
                    srcSet="/images/why/why-bg1-lg.png"
                  />
                  <img srcSet="/images/why/why-bg1.png" />
                </picture>
              </article>
            </Grid>
            <Grid item lg={6} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap">
                  <img className="why-card__icon" src={Percent} />

                  <h3 className="why-card__title">
                    {__("main.why.i-2_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-2_text")}</p>
                </div>

                <picture className="why-card__pic">
                  <source
                    media="(max-width: 1440px)"
                    srcSet="/images/why/why-bg2-lg.png"
                  />
                  <img src="/images/why/why-bg2.png" />
                </picture>
              </article>
            </Grid>
            <Grid item lg={6} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap">
                  <img className="why-card__icon" src={Percent} />

                  <h3 className="why-card__title">
                    {__("main.why.i-3_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-3_text")}</p>
                </div>

                <picture className="why-card__pic">
                  <source
                    media="(max-width: 1440px)"
                    srcSet="/images/why/why-bg3-lg.png"
                  />
                  <img src="/images/why/why-bg3.png" />
                </picture>
              </article>
            </Grid>
            <Grid item lg={6} xs={12}>
              <article className="why-card">
                <div className="why-card__wrap">
                  <img className="why-card__icon" src={Percent} />

                  <h3 className="why-card__title">
                    {__("main.why.i-4_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-4_text")}</p>
                </div>

                <picture className="why-card__pic">
                  <source
                    media="(max-width: 1440px)"
                    srcSet="/images/why/why-bg-lg.png"
                  />
                  <img src="/images/why/why-bg.png" />
                </picture>
              </article>
            </Grid>
            <Grid item xs={12}>
              <article className="why-card why-card--big">
                <img className="why-card__icon" src={Percent} />

                <div className="why-card__wrap">
                  <h3 className="why-card__title">
                    {__("main.why.i-5_title")}
                  </h3>
                  <p className="why-card__text">{__("main.why.i-5_text")}</p>
                </div>

                <picture className="why-card__pic">
                  <source
                    media="(max-width: 957px)"
                    srcSet="/images/why/bg-big-375.png"
                  />
                  <source
                    media="(max-width: 1440px)"
                    srcSet="/images/why/bg-big-lg.png"
                  />
                  <img src="/images/why/bg-big.png" />
                </picture>
              </article>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="offer">
        <div className="wrapper">
          <div className="offer__wrapper">
            <div className="offer__left">
              <h2 className="offer__title">{__("main.offer.title")}</h2>
              <p className="offer__text">{__("main.offer.text")}</p>
              <Link
                to="/auth/signup"
                className="offer__btn btn btn--middle btn--orange"
              >
                {__("main.offer.start")}
              </Link>
            </div>

            <picture className="offer__pic">
              <source
                media="(max-width: 957px)"
                srcSet="/images/offer/offer-375.png"
              />
              <source
                media="(max-width: 1440px)"
                srcSet="/images/offer/offer-lg.png"
              />
              <img src="/images/offer/offer.png" />
            </picture>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Main;
