const create = {
  en: {
    "login.button.title": "Log in",
    "settings.lang.en": "English",
    "settings.lang.es": "Spanish",

    "common.trading": "Trading",
    "common.wallet": "Wallet",
    "common.profile": "Profile",
    "common.history": "History",
    "common.settings": "Settings",
    "common.theme": "Theme",
    "common.logout": "Logout",
    "common.trade": "Trade",
    "common.real": "Real account",
    "common.demo": "Demo account",

    "common.deposit": "Deposit",
    "common.deposit2": "Deposit",
    "common.trading_pairs": "Trading pairs",
    "common.forex": "Forex",
    "common.crypto": "Crypto",
    "common.fiat": "Fiat",
    "common.date": "Date",
    "common.payment_method": "Payment method",
    "common.payee_wallet": "Wallet",
    "common.amount": "Amount",
    "common.status": "Status",
    "common.network": "Network",
    "common.commission": "Commission",
    "common.type": "Type",

    "common.not_found": "Not found",
    "common.all": "All",
    "common.verified": "Verified",
    "common.pending": "Pending",
    "common.unverified": "Unverified",
    "common.rejected": "Rejected",

    "common.cryptocurrency": "Cryptocurrency",
    "common.enter_amount": "Enter Amount",
    "common.enter_password": "Enter password",
    "common.password": "Password",
    "common.email": "Email",
    "common.enter_email": "Email",
    "common.enter_code": "Enter code",

    "common.theme_type": "Theme type",
    "common.green": "Green",
    "common.blue": "Blue",
    "common.orange": "Orange",
    "common.red": "Red",
    "common.white": "White",
    "common.dark_green": "Dark green",

    "common.back_to_login": "Back to Login",

    "common.cancel": "Cancel",
    "common.confirm": "Confirm",
    "common.successful": "Successful",
    "common.failed": "Failed",
    "common.warning": "Let’s get real",

    "common.banned": "You are banned!",
  },
  ru: {
    "login.button.title": "Войти",
    "settings.lang.en": "English",
    "settings.lang.es": "Spanish",

    "common.trading": "Торговля",
    "common.wallet": "Кошелек",
    "common.profile": "Профиль",
    "common.history": "История",
    "common.settings": "Настройки",
    "common.theme": "Темы",
    "common.logout": "Выйти",
    "common.trade": "Торговля",
    "common.real": "Счет",
    "common.demo": "Демо счет",

    "common.deposit": "Пополнить баланс",
    "common.deposit2": "Пополнить",
    "common.trading_pairs": "Торговые инструменты",
    "common.forex": "Forex",
    "common.crypto": "Криптовалюта",
    "common.fiat": "Fiat",
    "common.date": "Дата",
    "common.payment_method": "Платежный шлюз",
    "common.payee_wallet": "Кошелек",
    "common.amount": "Сумма",
    "common.status": "Статус",
    "common.network": "Сеть",
    "common.commission": "Комиссия",
    "common.type": "Тип",

    "common.not_found": "Не найдено",
    "common.all": "Все",
    "common.verified": "Верифицированы",
    "common.pending": "В ожидании",
    "common.unverified": "Неверифицированный",
    "common.rejected": "Отклоненный",

    "common.cryptocurrency": "Криптовалюта",
    "common.enter_amount": "Введите сумму",
    "common.enter_password": "Введите пароль",
    "common.password": "Пароль",
    "common.email": "E-mail",
    "common.enter_email": "E-mail",
    "common.enter_code": "Введите 2FA код",

    "common.theme_type": "Тема",
    "common.green": "Green",
    "common.blue": "Blue",
    "common.orange": "Orange",
    "common.red": "Red",
    "common.white": "White",
    "common.dark_green": "Dark green",

    "common.cancel": "Отмена",
    "common.confirm": "Подтвердить",
    "common.successful": "Успешно",
    "common.failed": "Неудача",
    "common.warning": "Предупреждение",

    "common.back_to_login": "Вернуться на страницу авторизации",

    "common.banned": "Вы заблокированы!",
  },

  es: {
    "login.button.title": "Iniciar sesión",
    "settings.lang.en": "Inglés",
    "settings.lang.es": "Español",

    "common.trading": "Trading",
    "common.wallet": "Cartera",
    "common.profile": "Perfil",
    "common.history": "Historial",
    "common.settings": "Ajustes",
    "common.theme": "Tema",
    "common.logout": "Cerrar sesión",
    "common.trade": "Comerciar",
    "common.real": "Cuenta real",
    "common.demo": "Cuenta demo",

    "common.deposit": "Depósito",
    "common.deposit2": "Depósito",
    "common.trading_pairs": "Pares de trading",
    "common.forex": "Forex",
    "common.crypto": "Criptomonedas",
    "common.fiat": "Fiat",
    "common.date": "Fecha",
    "common.payment_method": "Método de pago",
    "common.payee_wallet": "Cartera",
    "common.amount": "Cantidad",
    "common.status": "Estado",
    "common.network": "Red",
    "common.commission": "Comisión",
    "common.type": "Tipo",

    "common.not_found": "No encontrado",
    "common.all": "Todos",
    "common.verified": "Verificado",
    "common.pending": "Pendiente",
    "common.unverified": "No verificado",
    "common.rejected": "Rechazado",

    "common.cryptocurrency": "Criptomoneda",
    "common.enter_amount": "Introducir cantidad",
    "common.enter_password": "Introducir contraseña",
    "common.password": "Contraseña",
    "common.email": "Correo electrónico",
    "common.enter_email": "Correo electrónico",
    "common.enter_code": "Introducir código",

    "common.theme_type": "Tipo de tema",
    "common.green": "Verde",
    "common.blue": "Azul",
    "common.orange": "Naranja",
    "common.red": "Rojo",
    "common.white": "Blanco",
    "common.dark_green": "Verde oscuro",

    "common.back_to_login": "Volver al inicio de sesión",

    "common.cancel": "Cancelar",
    "common.confirm": "Confirmar",
    "common.successful": "Exitoso",
    "common.failed": "Fallido",
    "common.warning": "Nos vamos a real",

    "common.banned": "¡Estás baneado!",
  },
};

export default create;
