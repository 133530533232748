export const error = {
  en: {
    "error.email_format": "* Invalid email format",
    "error.invalid_code": "*Invalid code. Please try again",
    "error.invalid_code_format": "* Invalid code format",
    "error.pass_required": "*Password required",
    "error.incorrect_email": "Incorrect email address",
    "error.invalid": "Incorrect email address",
    "error.pass_different": "*Passwords do not match",
  },

  ru: {
    "error.email_format": "* Неверный e-mail формат",
    "error.invalid_code": "* Неверный код. Пожалуйста, попробуйте еще раз",
    "error.invalid_code_format": "* Неверный формат кода",
    "error.pass_required": "* Требуется пароль",
    "error.incorrect_email": "Неверный e-mail адрес",
    "error.pass_different": "*Пароли не совпадают",
  },

  es: {
    "error.email_format": "* Formato de correo electrónico inválido",
    "error.invalid_code": "* Código inválido. Por favor, inténtalo de nuevo",
    "error.invalid_code_format": "* Formato de código inválido",
    "error.pass_required": "* Contraseña requerida",
    "error.incorrect_email": "Dirección de correo electrónico incorrecta",
    "error.invalid": "Dirección de correo electrónico incorrecta",
    "error.pass_different": "* Las contraseñas no coinciden",
  },
};

export default error;
