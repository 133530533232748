export const wallet = {
  en: {
    "wallet.available_balance": "Available balance",
    "wallet.back": "Back to Wallet",
    "wallet.trade": "Trade",
    "wallet.withdrawal": "Withdrawal",
    "wallet.deposit": "Deposit",
    "wallet.transaction_history": "Transaction History",
    "wallet.credited_amount": "Credited amount",
    "wallet.destination": "Destination",
    "wallet.hash": "TX Hash",
    "wallet.enter_amount": "Enter the amount USD",
    "wallet.number": "Wallet number",
    "wallet.min_deposit": "Minimum deposit amount",
    "wallet.min_withdraw": "Minimum withdraw amount",
    "wallet.max_amount": "Max amount per transaction",
    "wallet.withdrawal_funds": "Withdrawal funds",
    "wallet.fee": "Fee",
    "wallet.received": "Will be received",
    "wallet.select_currency": "Select currency",
    "wallet.status": "Status",

    "wallet.cryptocurrencies": "Cryptocurrencies",

    "wallet.confirm_withdrawal": "Confirm withdrawal",
    "wallet.confirm_withdrawal_text": "Confirm this action to withdrawal",
    "wallet.currency": "Currency",
    "wallet.platform_fee": "Platform Fee",
    "wallet.will_be_withdrawn": "Will be withdrawn",
    "wallet.enter_2fa": "Enter the 2FA 6-digit Code",
    "wallet.digit": "6-digit Code",
    "wallet.warningDemo":
      "You are using a Demo account, it is not possible to withdraw/deposit money, do you want to change to a Real account?",
    "wallet.warningUnverified":
      "You will not be able to withdraw money until you complete the verification process.",
    "wallet.send2FA": "Send 2FA code",
    "wallet.deposit_popup1": "The deposit in the amount of",
    "wallet.deposit_popup2": "was successful",
    "wallet.deposit_popup3": "was failed",
    "wallet.deposit_type": "Deposit",
    "wallet.withdraw_type": "Withdraw",
    "wallet.no_limits": "no limits",
  },

  ru: {
    "wallet.available_balance": "Доступный баланс",
    "wallet.back": "Вернуться в Кошелек",
    "wallet.trade": "Торговля",
    "wallet.withdrawal": "Вывести средства",
    "wallet.deposit": "Внести средства",
    "wallet.transaction_history": "История транзакций",
    "wallet.credited_amount": "Зачисленная сумма",
    "wallet.destination": "Адрес",
    "wallet.hash": "Хэш транзакции",
    "wallet.enter_amount": "Укажите сумму в USD",
    "wallet.number": "Адрес кошелька",
    "wallet.min_deposit": "Мин. сумма депозита",
    "wallet.min_withdraw": "Мин. сумма вывода",
    "wallet.max_amount": "Макc. сумма за транзакцию",
    "wallet.withdrawal_funds": "Вывести средства",
    "wallet.fee": "Комиссия",
    "wallet.received": "Вы получите",
    "wallet.select_currency": "Выберите валюту",
    "wallet.status": "Статус",
    "wallet.no_limits": "безлимит",

    //Нове

    "wallet.cryptocurrencies": "Криптовалюты",

    "wallet.confirm_withdrawal": "Подтвердить вывод",
    "wallet.confirm_withdrawal_text":
      "Подтвердите данное действие для вывода средств",
    "wallet.currency": "Валюта",
    "wallet.platform_fee": "Комиссия платформы",
    "wallet.will_be_withdrawn": "Будет выведено",
    "wallet.enter_2fa": "Введите 6-значный код 2FA",
    "wallet.digit": "6-значный код",
    "wallet.warningDemo":
      "Вы используете демо-счет, вывести/внести деньги невозможно. Хотите перейти на реальный счет?",
    "wallet.warningUnverified":
      "Вы не сможете вывести средства до завершения процедуры верификации.",
    "wallet.send2FA": "Отправить код 2FA",
    "wallet.deposit_popup1": "Депозит в размере",
    "wallet.deposit_popup2": "был успешным",
    "wallet.deposit_popup3": "был неудачным",
    "wallet.deposit_type": "Пополнение",
    "wallet.withdraw_type": "Вывод",
  },

  es: {
    "wallet.available_balance": "Saldo disponible",
    "wallet.back": "Volver a la cartera",
    "wallet.trade": "Operar",
    "wallet.withdrawal": "Retiro",
    "wallet.deposit": "Depósito",
    "wallet.transaction_history": "Historial de transacciones",
    "wallet.credited_amount": "Cantidad acreditada",
    "wallet.destination": "Destino",
    "wallet.hash": "Hash TX",
    "wallet.enter_amount": "Ingresa la cantidad en USD",
    "wallet.number": "Número de billetera",
    "wallet.min_deposit": "Cantidad mínima de depósito",
    "wallet.min_withdraw": "Cantidad mínima de retiro",
    "wallet.max_amount": "Cantidad máxima por transacción",
    "wallet.withdrawal_funds": "Retirar fondos",
    "wallet.fee": "Tarifa",
    "wallet.received": "Se recibirá",
    "wallet.select_currency": "Seleccionar moneda",
    "wallet.status": "Estado",

    "wallet.cryptocurrencies": "Criptomonedas",

    "wallet.confirm_withdrawal": "Confirmar retiro",
    "wallet.confirm_withdrawal_text": "Confirma esta acción para retirar",
    "wallet.currency": "Moneda",
    "wallet.platform_fee": "Tarifa de plataforma",
    "wallet.will_be_withdrawn": "Será retirado",
    "wallet.enter_2fa": "Ingresa el código de 2FA de 6 dígitos",
    "wallet.digit": "Código de 6 dígitos",
    "wallet.warningDemo":
      "Estás usando una cuenta de Demo, no es posible retirar/depositar dinero, ¿quieres cambiar a una cuenta Real?",
    "wallet.warningUnverified":
      "No podrás retirar dinero hasta que completes el proceso de verificación.",
    "wallet.send2FA": "Enviar código 2FA",
    "wallet.deposit_popup1": "El depósito por un monto de",
    "wallet.deposit_popup2": "fue exitoso",
    "wallet.deposit_popup3": "falló",
    "wallet.deposit_type": "Depósito",
    "wallet.withdraw_type": "Retiro",
    "wallet.no_limits": "sin límites",
  },
};

export default wallet;
