const config = {
  // Configuration file
  api_url: process.env.REACT_APP_API_URL,

  default_locale: "en",
  default_theme: "orange",
  default_title: "Reviarte",
  local_state_name: "reviarte_state",
  default_pair: {
    slug: "EURUSD",
    name: "EURUSD",
    cover: `../images/icons/EUR/USD.svg`,
    pricescale: "100000",
  },
  supported_locales: process.env.REACT_APP_LANGS.split(","),
  pusher_key: process.env.REACT_APP_PUSHER_KEY,
  pusher_cluster: process.env.REACT_APP_PUSHER_CLUSTER,

  infoEmail: "info@reviarte.com",
  supportEmail: "support@help.reviarte.com",
};

export default config;
