import config from "../config";

export const seo = {
  en: {
    "seo.divider": "|",
    "seo.title": `${config.default_title}`,

    "seo.main_title": "The right place for online trading",
    "seo.main_description":
      "Welcome to the most contemporary and refined Platform for trading on financial markets. Create your free account and start using all the available trading opportunities and features to earn on trading.",

    "seo.create_account": "Create account",
    "seo.log_in": "Log in",
    "seo.reset_password": "Reset password",
    "seo.transaction_history": "Transaction History",
    "seo.deposit_funds": "Deposit",
    "seo.withdraw_funds": "Withdrawal",
    "seo.my_profile": "My Profile",
    "seo.trading_history": "Trading History",
    "seo.settings": "Settings",
    "seo.change_phone_number": "Change phone number",
    "seo.change_password": "Change password",
    "seo.two_factor_auth": "Two Factor Authentication",
  },

  ru: {
    "seo.divider": "|",
    "seo.title": `${config.default_title}`,

    "seo.main_title": "Онлайн трейдинг начинается здесь",
    "seo.main_description":
      "Добро пожаловать на самую современную и совершенную платформу для торговли на финансовых рынках. Создайте бесплатную учетную запись и начните использовать все доступные торговые возможности и функции, чтобы зарабатывать на торговле.",

    "seo.create_account": "Зарегистрироваться",
    "seo.log_in": "Войти",
    "seo.reset_password": "Восстановление пароля",
    "seo.transaction_history": "История транзакций",
    "seo.deposit_funds": "Внести средства",
    "seo.withdraw_funds": "Вывести средства",
    "seo.my_profile": "Мой профиль",
    "seo.trading_history": "История торгов",
    "seo.settings": "Настройки",
    "seo.change_phone_number": "Изменить номер телефона",
    "seo.change_password": "Изменить пароль",
    "seo.two_factor_auth": "Двухфакторная аутентификация",
  },

  es: {
    "seo.divider": "|",
    "seo.title": `${config.default_title}`,

    "seo.main_title": "El lugar adecuado para el trading en línea",
    "seo.main_description":
      "Bienvenido a la Plataforma más contemporánea y refinada para el trading en los mercados financieros. Crea tu cuenta gratuita y comienza a utilizar todas las oportunidades de trading y características disponibles para ganar dinero con el trading.",

    "seo.create_account": "Crear cuenta",
    "seo.log_in": "Iniciar sesión",
    "seo.reset_password": "Restablecer contraseña",
    "seo.transaction_history": "Historial de transacciones",
    "seo.deposit_funds": "Depósito",
    "seo.withdraw_funds": "Retiro",
    "seo.my_profile": "Mi perfil",
    "seo.trading_history": "Operaciones",
    "seo.settings": "Ajustes",
    "seo.change_phone_number": "Cambiar número de teléfono",
    "seo.change_password": "Cambiar contraseña",
    "seo.two_factor_auth": "Autenticación de dos factores",
  },
};

export default seo;
