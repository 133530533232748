import React from "react";

export const TickIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.9998"
        cy="12.4998"
        r="9.00375"
        stroke="#23C381"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44238 12.8387L10.6103 15.0066L10.5963 14.9926L15.4873 10.1016"
        stroke="#23C381"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
