import React from "react";
import { Link, useLocation } from "react-router-dom";
import Unauthorized from "@components/layout/Header/components/Unauthorized";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Authorized from "@components/layout/Header/components/Authorized";
import TradingPairs from "@components/layout/Header/components/TradingPairs";
import { MAIN_PAGE, TRADING_PAGES } from "@constants";
import { useSelector } from "react-redux";
import { LogoIcon } from "@components/library";
// import { ListOfPairs } from "@components/layout/Header/components/ListOfPairs";
// import { BrowserView } from "react-device-detect";

import "./header.sass";

export const Header = ({ setSidebar }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  // const isTablet = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  const { isAuth, isDemoUser } = useSelector((state) => state.auth);

  const isAuthRoute = (pathname) => pathname.startsWith("/auth/");
  const isMainPage = (pathname) => pathname === MAIN_PAGE;

  return (
    <header className="header header--fixed">
      <div className="header__wrapper">
        {isDesktop ||
        ((!isAuth || (isAuth && isDemoUser && isDesktop)) && !isDesktop) ? (
          <div className="header__left">
            <>
              <Link className="header__logo" to="/">
                <LogoIcon />
              </Link>

              {isAuth &&
              isDemoUser &&
              (isMainPage(location.pathname) ||
                isAuthRoute(location.pathname)) ? (
                <></>
              ) : isAuth && TRADING_PAGES.includes(location.pathname) ? (
                <>
                  <TradingPairs />

                  {/*{isTablet && (*/}
                  {/*  <BrowserView>*/}
                  {/*    <ListOfPairs />*/}
                  {/*  </BrowserView>*/}
                  {/*)}*/}
                </>
              ) : (
                <></>
              )}
            </>
          </div>
        ) : (
          <></>
        )}
        {isAuth &&
        isDemoUser &&
        (isMainPage(location.pathname) || isAuthRoute(location.pathname)) ? (
          <Unauthorized />
        ) : isAuth ? (
          <Authorized setSidebar={setSidebar} />
        ) : (
          <Unauthorized />
        )}
      </div>
    </header>
  );
};
