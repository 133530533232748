import React, { useState } from "react";
import Global from "@assets/images/icons/global.svg";
import GB from "@assets/images/icons/gb.svg";
import RU from "@assets/images/icons/ru.svg";
import ES from "@assets/images/icons/es.svg";
import { Menu, MenuItem } from "@mui/material";
import Fade from "@mui/material/Fade";

import "../header.sass";
import { Button } from "@components/library";
import { useDispatch } from "react-redux";
import { LOCALE } from "@constants";
import { useTranslation } from "@helpers/translate";
import config from "../../../../config";
const Unauthorized = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const { __ } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    dispatch({ type: LOCALE.CHANGE_LOCALE, payload: myValue });
  };

  return (
    <div className="header__right">
      <nav>
        <ul className="header__nav-list">
          <li className="header__nav-item">
            <Button
              type="link"
              className="header__login header__btn"
              to="/auth/login"
              color="black"
              size="mini"
            >
              {__("login.button.title")}
            </Button>
          </li>
          <li className="header__nav-item">
            <Button
              type="link"
              className="header__signUp header__btn"
              to="/auth/signup"
              color="orange"
              size="mini"
            >
              {__("auth.sign_up")}
            </Button>
          </li>
          <li className="header__nav-item">
            <div>
              <button
                onClick={handleClick}
                type="button"
                className="header__mini-btn"
              >
                <img src={Global} alt="lang" />
              </button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                className="mini-select"
              >
                {config.supported_locales.includes("en") && (
                  <MenuItem data-my-value="en" onClick={handleChange}>
                    <div className="header__change-btn">
                      <img src={GB} alt="lang" />
                      <p className="header__change-btn-text">English</p>
                    </div>
                  </MenuItem>
                )}
                {config.supported_locales.includes("ru") && (
                  <MenuItem data-my-value="ru" onClick={handleChange}>
                    <div className="header__change-btn">
                      <img src={RU} alt="lang" />
                      <p className="header__change-btn-text">Русский</p>
                    </div>
                  </MenuItem>
                )}
                {config.supported_locales.includes("es") && (
                  <MenuItem data-my-value="es" onClick={handleChange}>
                    <div className="header__change-btn">
                      <img src={ES} alt="lang" />
                      <p className="header__change-btn-text">Español</p>
                    </div>
                  </MenuItem>
                )}
              </Menu>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Unauthorized;
