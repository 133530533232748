import config from "../config";

export const trading = {
  en: {
    "trading.trading_history": "Trading History",
    "trading.amount": "Amount",
    "trading.time": "Time",
    "trading.up": "Call",
    "trading.down": "Put",
    "trading.pair": "Pair",
    "trading.payout": "Payout",
    "trading.profit": "Profit",
    "trading.open_time": "Open time",
    "trading.closing_at": "Closing time",
    "trading.forecast": "Your forecast",
    "trading.status": "Status",
    "trading.open_price": "Open price",
    "trading.current_price": "Current price",
    "trading.difference": "Difference",
    "trading.opened": "Opened",
    "trading.closed": "Closed",
    "trading.open_bids": "You don't have any open bids",
    "trading.close_bids": "You don't have any close bids",
    "trading.pairs_nf": "No matching pairs found.",
    "trading.no_pairs": "No matching pairs.",
    "trading.bid_forecast": "Forecast",
    "trading.bid_amount": "Amount",

    "trading.for_register":
      "This section is only available to registered users. Create an account on the platform to trade and earn profits",
    "trading.continue_demo": "Continue trading on Demo",

    "trading.gift": "5000$ gift",
    "trading.gift_text":
      "5000$ credited to your Demo account. Or skip and Deposit now",
    "trading.skip_btn": "Skip and Deposit now",
    "trading.trade_now": "Trade now",

    "trading.welcome": `Welcome to the trading platform ${config.default_title}`,
    "trading.demo_trading": "Demo Trading",
    "trading.welcome_deposit":
      "Get high passive income monthly! Minimum investment, guaranteed payouts.",
    "trading.welcome_deposit_now": "Deposit now",

    "trading.10s": "10s",
    "trading.1m": "1m",
    "trading.3m": "3m",
    "trading.5m": "5m",
    "trading.10m": "10m",
    "trading.15m": "15m",
    "trading.30m": "30m",
    "trading.1h": "1h",
    "trading.4h": "4h",
  },
  ru: {
    "trading.trading_history": "История торгов",
    "trading.amount": "Сумма",
    "trading.time": "Время",
    "trading.up": "Вверх",
    "trading.down": "Вниз",
    "trading.pair": "Актив",
    "trading.payout": "Выплата",
    "trading.profit": "Прибыль",
    "trading.open_time": "Время открытия",
    "trading.closing_at": "Время закрытия",
    "trading.forecast": "Ваш прогноз",
    "trading.status": "Статус",
    "trading.open_price": "Цена открытия",
    "trading.current_price": "Текущая цена",
    "trading.difference": "Разница",
    "trading.opened": "Открытые",
    "trading.closed": "Закрытые",
    "trading.open_bids": "У вас нет открытых ставок",
    "trading.close_bids": "У вас нет закрытых ставок",
    "trading.pairs_nf": "Совпадающих пар не найдено.",
    "trading.no_pairs": "Нет совпадающих пар.",
    "trading.bid_forecast": "Прогноз",
    "trading.bid_amount": "Сумма",

    "trading.for_register":
      "Этот раздел доступен только зарегистрированным пользователям. Создайте учетную запись на платформе, чтобы торговать и получать прибыль",
    "trading.continue_demo": "Продолжить торговлю на демо-версии",

    "trading.gift": "5000$ подарок",
    "trading.gift_text":
      "5000$ зачислены на ваш демо-счет. Или пропустите и внесите депозит сейчас",
    "trading.skip_btn": "Пропустить и внести депозит сейчас",
    "trading.trade_now": "Торговать сейчас",

    "trading.welcome": `Добро пожаловать на торговую платформу ${config.default_title}`,
    "trading.demo_trading": "Демо-торговля",
    "trading.welcome_deposit":
      "Получайте высокий passivный доход ежемесячно! Минимальные инвестиции, гарантированные выплаты.",
    "trading.welcome_deposit_now": "Внести депозит сейчас",

    "trading.10s": "10с",
    "trading.1m": "1м",
    "trading.3m": "3м",
    "trading.5m": "5м",
    "trading.10m": "10м",
    "trading.15m": "15м",
    "trading.30m": "30м",
    "trading.1h": "1ч",
    "trading.4h": "4ч",
  },

  es: {
    "trading.trading_history": "Operaciones",
    "trading.amount": "Cantidad",
    "trading.time": "Hora",
    "trading.up": "Compra",
    "trading.down": "Venta",
    "trading.pair": "Par",
    "trading.payout": "Pago",
    "trading.profit": "Ganancia",
    "trading.open_time": "Hora de apertura",
    "trading.closing_at": "Hora de cierre",
    "trading.forecast": "Tu predicción",
    "trading.status": "Estado",
    "trading.open_price": "Precio de apertura",
    "trading.current_price": "Precio actual",
    "trading.difference": "Diferencia",
    "trading.opened": "Abierto",
    "trading.closed": "Cerrado",
    "trading.open_bids": "No tienes ninguna oferta abierta",
    "trading.close_bids": "No tienes ninguna oferta cerrada",
    "trading.pairs_nf": "No se encontraron pares coincidentes.",
    "trading.no_pairs": "No hay pares coincidentes.",
    "trading.bid_forecast": "Predicción",
    "trading.bid_amount": "Cantidad",

    "trading.for_register":
      "Esta sección solo está disponible para usuarios registrados. Crea una cuenta en la plataforma para operar y obtener ganancias",
    "trading.continue_demo": "Continuar operando en Demo",

    "trading.gift": "Regalo de 5000$",
    "trading.gift_text":
      "5000$ acreditados en tu cuenta de Demo. O saltar y depositar ahora",
    "trading.skip_btn": "Saltar y depositar ahora",
    "trading.trade_now": "Operar ahora",

    "trading.welcome": `Bienvenido a la plataforma de trading ${config.default_title}`,
    "trading.demo_trading": "Trading de demostración",
    "trading.welcome_deposit":
      "¡Obtén altos ingresos pasivos mensuales! Inversión mínima, pagos garantizados.",
    "trading.welcome_deposit_now": "Depositar ahora",

    "trading.10s": "10s",
    "trading.1m": "1m",
    "trading.3m": "3m",
    "trading.5m": "5m",
    "trading.10m": "10m",
    "trading.15m": "15m",
    "trading.30m": "30m",
    "trading.1h": "1h",
    "trading.4h": "4h",
  },
};

export default trading;
