import React, { useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { RefreshIcon } from "@components/library";
import { AnimatedCounter } from "react-animated-counter";
import { changeWallet, getWallets, updateWalletEvent } from "@actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "@helpers/translate";

const Mode = () => {
  const dispatch = useDispatch();
  const { active_wallet, wallets } = useSelector((state) => state.wallet);
  const { __ } = useTranslation();

  const changeWalletAction = (e) => {
    const wallet = wallets.find((item) => item.type === e.target.value);
    dispatch(changeWallet(wallet));
  };

  useEffect(() => {
    dispatch(getWallets());
    dispatch(updateWalletEvent());
  }, []);

  return (
    <Select
      MenuProps={{
        className: "mode-select-menu",
      }}
      variant="standard"
      className="mode-select"
      value={active_wallet.type ? active_wallet.type : "demo"}
      onChange={changeWalletAction}
      autoWidth
      IconComponent={(props) => {
        return (
          <svg
            {...props}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9467 5.45312H7.79341H4.05341C3.41341 5.45312 3.09341 6.22646 3.54674 6.67979L7.00008 10.1331C7.55341 10.6865 8.45341 10.6865 9.00674 10.1331L10.3201 8.81979L12.4601 6.67979C12.9067 6.22646 12.5867 5.45312 11.9467 5.45312Z"
              fill="#707070"
            />
          </svg>
        );
      }}
    >
      {wallets.map((wallet) => {
        return (
          <MenuItem key={wallet.id} value={wallet.type}>
            <div className="mode-item">
              <RefreshIcon className="mode-item__icon" />
              <div className="mode-item__content">
                <p className="mode-item__name">
                  {wallet.type === "demo"
                    ? __("common.demo")
                    : __("common.real")}
                </p>
                <h5 className="mode-item__value">
                  <AnimatedCounter
                    value={wallet.balance}
                    fontSize="11px"
                    color="#ffffff"
                  />{" "}
                  USD
                </h5>
              </div>
            </div>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default Mode;
