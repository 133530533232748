import axios from "axios";
import store from "../../../../../redux/store";
import moment from "moment-timezone";

const configurationData = {
  supported_resolutions: ["1", "5"],
};

let currentSub;

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback
    // onErrorCallback,
  ) => {
    console.log(
      "[getBars] Method call",
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback
    );

    try {
      const { firstDataRequest } = periodParams;
      //
      // if (!firstDataRequest) return;

      const from = moment(periodParams.from * 1000)
        .tz("UTC")
        .format("YYYY-MM-DD-HH:mm");
      const to = moment(periodParams.to * 1000).tz("UTC");

      if (firstDataRequest) {
        to.subtract(1, "minute");
      }

      const formattedTo = to.format("YYYY-MM-DD-HH:mm");

      const resp = await axios.get(
        `https://marketdata.tradermade.com/api/v1/timeseries?currency=${symbolInfo.ticker}&api_key=QNvOskC2kxVQNfxi6jlS&start_date=${from}&end_date=${formattedTo}&format=records&period=${resolution}&interval=minute`
      );

      console.log("Dates:", from, to, resp);

      const data = resp.data.quotes.map((obj) => {
        return {
          time: moment(obj.date).add(3, "hours").valueOf(),
          ...obj,
        };
      });

      if (data.length < 1) {
        onHistoryCallback([], { noData: true });
      } else {
        onHistoryCallback(data, { noData: false });
      }
    } catch (e) {
      onHistoryCallback([], { noData: true });
    }
  },
  resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
    console.log("resolveSymbol");
    const state = store.getState();

    const pricescale = Number(state.trading.pair.pricescale)
      ? Number(state.trading.pair.pricescale)
      : 1;

    console.log(pricescale, "pricescale", state.trading.pair.pricescale);

    const symbol = {
      ticker: symbolName,
      session: "24x7",
      // timezone: "Etc/UTC",
      minmov: 1,
      pricescale,
      has_intraday: true,
      // intraday_multipliers: ["1", "5"],
      // has_empty_bars: true,
      has_weekly_and_monthly: false,
      // supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 1,
      data_status: "streaming",
    };

    onSymbolResolvedCallback(symbol);
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, id) => {
    console.log(
      "[subscribeBars]: Method call with subscribeUID:",
      symbolInfo,
      resolution
    );

    const newSub = {
      id,
      symbolInfo,
    };

    currentSub = newSub;

    let timer = null;

    window.Echo.channel(`SYMBOL.${symbolInfo.ticker}`).listen(
      "ChartUpdate",
      (e) => {
        clearTimeout(timer);
        console.log("ChartUpdate", currentSub.id, id);
        console.log(e.time, new Date(e.time));
        const realTimeData = {
          time: e.time,
          open: e.open,
          high: e.high,
          low: e.low,
          close: e.close,
        };

        timer = setTimeout(() => {
          if (currentSub.id === id) onRealtimeCallback(realTimeData);
        }, 500);
      }
    );
  },

  unsubscribeBars: (id) => {
    if (currentSub.symbolInfo.ticker === id.split("_")[0]) return;

    window.Echo.leave(`SYMBOL.${id.split("_")[0]}`);
  },
};
